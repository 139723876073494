<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('app.route.stores')" back-button>
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width: 0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn text small color="primary" :disabled="!selected" @click="editStoreProfile()">
          <v-icon>mdi-store-edit</v-icon>
          <span>{{ $t("app.route.storeProfile") }}</span>
        </v-btn>
        <v-btn text small color="primary" :disabled="!selected" @click="manageSections()">
          <v-icon>mdi-sitemap</v-icon>
          <span>{{ $t("app.route.sections") }}</span>
        </v-btn>
        <v-btn text small color="primary" :disabled="!selected" @click="manageProducts()">
          <v-icon>mdi-barcode-scan</v-icon>
          <span>{{ $t("app.route.products") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(84)">
        <v-data-table
          dense
          :headers="headers"
          :items="getAssignedStores"
          item-key="id"
          :search="search"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
          @click:row="selectionChanged"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="primary"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <template #item.name="{ item }">
            <v-avatar size="32" class="my-1 mr-1" style="border: 1px solid #eee">
              <v-img alt="Logo" :src="getServerImage(`stores/logos/_${item.uuid}.png`)"></v-img>
            </v-avatar>
            <span>{{ item.name }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions, mapState, Store } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "RegionStores",
  components: { ViewContainer, Toolbar },
  props: {
    regionId: {
      typeof: Number,
      default() {
        return -1;
      },
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 70 },
        { text: this.$t("general.name"), value: "name" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.clearSelectedStore();
    this.loadAssignedStores().then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters("stores", ["getAssignedStores"]),
    ...mapState({
      selected(state) {
        return state.stores.selected;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
    getRegionIdParam() {
      return parseInt(this.$route.params.regionId);
    },
  },
  methods: {
    ...mapActions("stores", [
      "loadAssignedStores",
      "reloadStoresByParams",
      "removeStoresFromState",
      "setSelectedStore",
      "clearSelectedStore",
    ]),
    ...mapActions("ui", [
      "showDialog",
      "closeDialog",
      "setReactivityParams",
      "clearReactivityParams",
    ]), //, "setBreadcrumbs"

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedStore(event.item);
      } else if (event.id && this.selected?.id !== event.id) {
        this.setSelectedStore(event);
      } else {
        this.clearSelectedStore();
      }
    },
    editStoreProfile() {
      if (this.selected) {
        this.$$router.push({
          name: "manager.store.profile",
          params: { storeId: this.selected.id, back: { name: this.$route.name } },
        });
      }
    },
    manageSections() {
      if (this.selected) {
        this.$$router.push({
          name: "manager.store.sections",
          params: { regionId: this.getRegionIdParam, storeId: this.selected.id, back: { name: this.$route.name } },
        });
      }
    },
    manageProducts() {
      if (this.selected) {
        this.$$router.push({
          name: "manager.store.products",
          params: { storeId: this.selected.id, back: { name: this.$route.name } },
        });
      }
    },
    changeStoreStatus() {
      if (this.selected) {
        // const dialog = {
        //   title: "Change Store Status",
        //   component: StoreStatusDialog,
        //   actions: [
        //     {
        //       text: "Yes",
        //       click: ({ disabled, status}) => {
        //         this.changeStoreStatus({
        //           id: this.selected.id,
        //           disabled,
        //           status,
        //         });
        //         this.closeDialog();
        //       },
        //       color: "primary",
        //     },
        //     {
        //       text: "No",
        //       click: () => this.closeDialog(),
        //       color: "primary",
        //     },
        //   ],
        // };

        // this.showDialog(dialog);
      }
    },
  },
};
</script>
